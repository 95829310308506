const Features = () => {
  return (
    <section className="bg-black" id="features">
      <div className="bg-gradient-to-b from-blue-700 to-black h-10"></div>
      <div className="max-w-6xl px-5 mx-auto mt-32 text-center">
        <h2 className="text-white text-4xl font-bold text-center">
          Get rewarded in $FAM EVERY DAY just by HOLDING $BABYFAM
        </h2>
        <h2 className="text-white mt-10 text-4xl font-bold text-center">
          🚀🚀🚀
        </h2>

        <div className="flex flex-col py-24 gap-4 md:flex-row md:space-x-6">
          <div className="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3">
            <h5 className="text-lg font-bold">LP-BURNT</h5>
            <p className="text-xl font-semibold text-darkBlue">
              The LP will be burnt on launch. As well as all the future LP raised by the LP fee!
            </p>
          </div>

          <div className="flex-col max-sm:mt-10 items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
            <h5 className="text-lg font-bold">4% $FAM REWARDS & 4% LP</h5>
            <p className="text-xl font-semibold text-darkBlue">
              $BABYFAM will have an 8% buy/sell fee. With 4% being used to buy $FAM and give it to our holders, and 4% going to LP.
            </p>
          </div>

          <div className="flex-col max-sm:mt-10 items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
            <h5 className="text-lg font-bold">REVOKED AUTHORITY</h5>
            <p className="text-xl font-semibold text-darkBlue">
              The mint, freeze, and transfer-fee update authority will all be revoked to create a SAFU environment for our holders.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
