import React from "react";

import logo from "../assets/images/fam.png";

const Hero = () => {
  return (
    <section
      id="hero"
      className="bg-gradient-to-b from-blue-500 via-lightBlue to-blue-700"
    >
      <div className="container flex flex-col-reverse items-center px-6 mx-auto space-y-12 md:space-y-0 md:flex-row md:space-x-4">
        <div className="flex flex-col mb-16 md:w-1/2">
          <h1 className="text-white mb-8 mt-8 text-3xl font-bold text-center md:text-5xl md:text-left">
            Welcome to $BABYFAM
          </h1>
          <p className=" text-center text-sm mb-4 font-semibold text-gray-200 md:text-xl md:text-left">
            A token created on the solana blockchain, meant to support $FAM and their journey to $1 BILLION MC!
            $BABYFAM is a token featuring the transfer fee extension, using the fees collected to buy $FAM every day and distribute
            it amongst the holders of $BABYFAM. Half of the fees will also be used to strengthen the liqiuidty of $BABYFAM 💪
          </p>
          <p className=" text-center text-sm mb-4 font-semibold text-gray-200 md:text-xl md:text-left">

          </p>
          <div className="flex justify-center md:justify-start space-x-4 mt-4">
            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center p-2 px-4 pt-1 md:p-3 md:px-6 md:pt-2 text-black font-bold bg-[#feeb1a] rounded-full baseline hover:bg-white"
            >
              BUY $BABYFAM
            </a>

            <a
              href="https://t.me/babyfamsol"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center p-2 px-4 pt-1 md:p-3 md:px-6 md:pt-2 text-black font-bold bg-[#feeb1a] rounded-full baseline hover:bg-white"
            >
              TELEGRAM
            </a>

            <a
              href=""
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center p-2 px-4 pt-1 md:p-3 md:px-6 md:pt-2 text-black font-bold bg-[#feeb1a] rounded-full baseline hover:bg-white"
            >
              CHART
            </a>
          </div>
        </div>
        <div className="md:w-1/2">
          <img src={logo} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
