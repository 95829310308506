import { BrowserRouter } from 'react-router-dom';

import Hero from './components/Hero';
import Community from './components/Community.jsx';
import Features from './components/Features';

function App() {
  return (
    <BrowserRouter>
      <Hero />
      <Features />
      <Community />
    </BrowserRouter>
  );
}

export default App;
