import React from "react";

import video from "../assets/images/video.mp4";

const Community = () => {
  return (
    <section className="bg-black" id="community">
      <div className="container flex flex-col items-center md:flex-row px-4 mx-auto space-y-12 md:space-y-0">
        <div className="flex flex-col md:w-1/2 items-center md:items-start">
          <div className="w-5/6 h-full">
            <video width="100%" height="auto" autoPlay loop muted playsInline>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="flex flex-col pl-10 justify-center md:w-1/2">
          <div className="flex flex-col space-y-3 md:space-y-0 md:space-x-6 md:flex-row">
            <div className="flex items-center space-x-2">
              <h3 className="text-white text-base font-bold md:mb-4 md:hidden">
                Gaining traction on social media
              </h3>
            </div>

            <div>
              <h3 className="text-white hidden mb-4 text-lg font-bold md:block">
                Gaining traction on social media
              </h3>
              <p className="text-gray-300 mb-4">
                We love what the $FAM team has done with their social media
                and plan to follow suit. We're also looking into using some of
                the transfer fee to incentivize community members to post about
                $BABYFAM and shill.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Community;
